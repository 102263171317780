<template>
    <div class="zt-page-content">
        <div class="tab flex">
            <div class="back" @click="back()">返回</div>
            <div class="flex-1">
                <el-tabs v-model="tabId" type="card" @tab-click="tab" closable @tab-remove="removeTab" style="width:100%">
                    <el-tab-pane
                        v-for="item in tabList"
                        :key="item.id"
                        :label="item.factory_name+item.group_name"
                        :name="item.id">
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <div class="thead flex">
            <!-- <div style="margin-right:30px;">
                <el-select v-model="queryOpt.set_id" @change="handleChange" :popper-append-to-body="false" placeholder="全部">
                    <el-option v-for="item in setList" :key="item.set_no"
                    :label="item.name" :value="item.set_no">
                    </el-option>
                </el-select>
            </div>
            <div class="input-block flex">
                <el-input v-model="searchValue" placeholder="输入公司名称" 
                    prefix-icon="el-icon-search"></el-input>
                <el-button @click="search">搜索</el-button>
            </div> -->
        </div>
        <div class="block" ref="load" style="height:calc(100% - 140px);">
            <div v-if="agentList && agentList.length>0" class="list flex" style="height:calc(100% - 50px);overflow:auto;">
                <div v-for="item in agentList" :key="item.id" class="item-block" @click="toDetail(item.id)" >
                    <img :src="imgUrl + item.photo_render[0]+'!width_250px'" />
                    <div class="name">{{ item.name }}</div>
                    <div class="feature">
                        <span style="margin-right:4px;" v-if="item.style && item.style.name">{{ item.style.name }}</span> 
                        <span style="margin-right:4px;" v-if="item.cat && item.cat.name">| {{ item.cat.name }}</span> 
                        <span>| {{ item.set && item.set.name }}</span>
                    </div>
                    <div class="price">¥{{ item.start_price }}<span>起</span></div>
                    <!-- <div class="watch-count">
                        已有{{ item.statistics && item.statistics.uv }}人查看
                    </div> -->
                    <div class="comp-info">
                        <div class="tit">{{ item.design_comp && item.design_comp.name }}</div>
                        <div class="style">
                            <span>
                                <i class="el-icon-medal"></i>{{ item.skuCount }}种款式
                            </span>
                            <span v-if="item.isProof" class="dayang">
                                <b> <i class="el-icon-check"></i> </b>已打样
                            </span>
                            <span v-else class="dayang">
                                <b style="border:1px solid #808080;"> </b>未打样
                            </span>
                        </div>
                        <div class="label">
                            <label class="color-1">现货</label>
                            <label class="color-2">高信用</label>
                            <label class="color-3">低价</label>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="agentList && agentList.length>0" style="padding:10px;border-top:1px solid #eee;">
                <el-pagination background layout="prev, pager, next" 
                    :current-page.sync="queryOpt.page" 
                    :page-size="queryOpt.pagesize" :total="total">
                </el-pagination>
            </div>
            <div v-if="agentList && agentList.length==0" class="flex flex-center" style="text-align:center;height:600px;">
                <div>
                    <img :src="staticUrl+'/design/placeholder/meiyougengduo.png'" alt="">
                    <div style="font-size:20px;color:#808080;padding:15px 0;">暂无相关数据</div>
                </div>
            </div>
        </div>
        
    </div>
</template>
<script>
import { IMG_URL_PRE,STATIC_URL_PRE } from "@/config";
import { getSets } from '@/service/item';
import { saleAgencyGroupProduct } from '@/service/white';
export default {
    model: {
        prop: "tagList",
        event: "change"
    },
    props:['tagId','tagList'],
    data() {
        return {
            imgUrl: IMG_URL_PRE,
            staticUrl:STATIC_URL_PRE,
            popVisible: false,
            popTitle: '',
            // 代理商分组
            tagIdx: '0',
            tabId:null,
            tabList:[],
            // 分组下的代理商
            setList:"", // 商品分类列表
            searchValue:"",
            queryOpt:{
                set_id: null,
                factory_id: null,
                group_id: null,
                page:1,
                pagesize:15,
            },
            total:0,
            agentList:[],
        }
    },
    watch: {
        tagId: {
            handler() {
                console.log("tagId",this.tagId);
                this.tabId = this.tagId;
                this.tabList = this.tagList;
            },
            deep: true
        },
    },
    async created() {
        this.setList = (await getSets({price_type:0})).concat({id:0,name:'全部'});
        this.tabId = this.tagId.toString();
        this.tabList = this.tagList;
        // console.log(this.tagList);
        this.refreshItems();
    },
    mounted() {},
    methods: {
        toDetail(id) {
            this.$router.push(`/sale/mall/home/factory_detail/${id}`);
        },
        goto(link) {
            this.$router.push(link);
        },
        back() {
            this.$emit('back');
        },
        handleChange(e) {

        },
        search() {},
        // 选项卡
        tab(event) {
            console.log(event.name);
            this.tabId = event.name;
            this.refreshItems();
        },
        onChange() {
            this.$emit("change", this.tabList);
        },
        removeTab(targetName) {
            let activeName = this.tabId;
            if (activeName === targetName) {
                this.tabList.forEach((tab, index) => {
                    if (tab.id === targetName) {
                        let nextTab = this.tabList[index + 1] || this.tabList[index - 1];
                        if (nextTab) {
                            activeName = nextTab.id;
                        }
                    }
                });
            }
            this.tabId = activeName;
            this.tabList = this.tabList.filter(tab => tab.id !== targetName);
            if (this.tabList.length==0) {
                this.back();
            } else {
                this.refreshItems()
            }
            this.onChange();
        },
        refreshItems() {
            let tabIdx = this.tabList.findIndex(e => e.id == this.tabId);
            this.queryOpt.factory_id = this.tabList[tabIdx].factory_id;
            this.queryOpt.group_id = this.tabList[tabIdx].group_id;
            this.queryOpt.page = 1;
            this.agent_list();
        },
        agent_list() {
            this.list = null;
            const _loading = this.$loading({
                fullscreen: true, text: '拼命加载中....',
                target:this.$refs.load
            });
            saleAgencyGroupProduct(this.queryOpt).then(rst => {
                this.agentList = rst.list;
                this.total = rst.total;
                console.log("agentList",this.agentList);
                _loading.close();
            }).catch(err => {
                _loading.close();
                console.log(err);
                this.$message.error(err.message);
            });
        },
    }
}
</script>
<style scoped src="../../style.css"></style>
<style scoped>
    .tab>>> .el-tabs__header {
        margin: 0;
    }
</style>
<style scoped lang="less">
.thead {
    max-width: 1220px;
    margin: 20px auto;
    .input-block {
        .el-input {
            max-width:200px;
        }
        .el-button {
            width: 76px;
            left: -4px;
            position: relative;
            z-index: 2;
        }
    }
}
/*----选项卡----*/
.tab {
    width: 100%;
    background: #fff;
    .el-tabs__header {
        margin: 0;
    }
    .back {
        padding: 0 20px;
        height: 40px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        line-height: 40px;
        display: inline-block;
        list-style: none;
        font-size: 14px;
        font-weight: 500;
        color: #303133;
        position: relative;
        cursor: pointer;
    }
}
.block {
    max-width: 1220px;
    margin: 0 auto; 
}
.list {
  min-height: 600px;
  padding: 30px 30px 0;
  background-color: #fff;
  position: relative;
  .item-block {
    width: 264px;
    height: 480px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 8px 0px rgba(204, 204, 204, 1);
    padding: 20px;
    color: #808080;
    margin: 0 28px 30px 0;
    transform: scale(1);
    transition: all 0.3s;
    border: 1px solid transparent;
    &:hover {
      border: 1px solid rgba(80, 116, 238, 1);
      transform: scale(1.064);
    }
    &:nth-child(4n) {
      margin-right: 0;
    }
    cursor: pointer;
    img {
      width: 224px;
      height: 224px;
    }
    .name {
      color: #292929;
      font-size: 16px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-top: 20px;
    }
    .feature {
      color: #808080;
      font-size: 12px;
      margin-top: 10px;
    }
    .price {
      color: #ee1818;
      font-size: 20px;
      margin-top: 10px;
      span {
        color: #808080;
        font-size: 12px;
      }
    }
    .watch-count {
      color: #b3b3b3;
      font-weight: 500;
      margin-top: 10px;
    }
    .label {
      margin-top: 10px;
      display: flex;
      label {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        border-radius: 2px;
        padding: 0 3px;
        margin-right: 9px;
        &.color-1 {
          color: #fff;
          background-color: #f66f6a;
        }
        &.color-2 {
          color: #5074ee;
          border: 1px solid #5074ee;
        }
        &.color-3 {
          color: #2bbaae;
          border: 1px solid #2bbaae;
        }
      }
    }
    .comp-info {
      margin-top: 10px;
      .tit {
        color: #292929;
        padding-top: 10px;
        border-top: 1px solid #ccc;
        font-weight: bold;
      }
      .style {
        font-size: 12px;
        margin-top: 10px;
        display: flex;
        span {
          margin-right: 18px;
          white-space: nowrap;
          display: flex;
          align-items: center;
          &:first-child {
            i {
              font-size: 14px;
            }
          }
        }
        .dayang {
          display: flex;
          align-items: center;
          white-space: nowrap;
          b {
            width: 12px;
            height: 12px;
            border: 1px solid #5074ee;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 6px;
            i {
              font-size: 14px;
              color: #5074ee;
            }
          }
        }
      }
    }
  }
}
</style>